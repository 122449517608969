// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from './Breadcrumb.styles';

const Breadcrumb = ({ children }) => {
  const items = React.Children.toArray(children);

  return (
    <nav aria-label='breadcrumbs'>
      <List>
        {items.map((item) => (
          <ListItem key={item.key}>{item}</ListItem>
        ))}
      </List>
    </nav>
  );
};

Breadcrumb.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Breadcrumb;
