// vendors
import React from 'react';
import { graphql } from 'gatsby';
import { PropTypes } from 'prop-types';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { hideVisually } from 'polished';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import generateLangLinks from '../../utils/generateLangLinks';
import generatePath from '../../utils/generatePath';
import Hero from '../../components/Hero/Hero';
import Breadcrumb, { BreadcrumbItem } from '../../components/Breadcrumb';
import CardGrid, { Card } from '../../components/CardGrid';
import {
  BreadcrumbContainer,
  ProductContainer,
  CTAContainer,
  CTAWrapper,
} from './ProductCategoryTemplate.styles';
import Button from '../../components/Button/Button';
import IconArrow from '../../images/IconArrow';

const messages = defineMessages({
  businessesPagePath: {
    defaultMessage: '/entreprises',
    description: 'Path to the businesses page',
  },
  businessesPageTitle: {
    defaultMessage: 'Entreprises',
    description: 'Title for the businesses page',
  },
  consumersPagePath: {
    defaultMessage: '/particuliers',
    description: 'Path to the consumers page',
  },
  consumersPageTitle: {
    defaultMessage: 'Particuliers',
    description: 'Title for the consumers page',
  },
});

const ProductCategoryTemplate = ({ data, pageContext, path }) => {
  const { formatMessage } = useIntl();
  const {
    category: {
      seo,
      targetAudience,
      localization: { translations = [] },
      pictures: [
        {
          asset: { heroPictureDesktop: desktop, heroPictureMobile: mobile },
        },
      ],
      ...category
    },
    products,
  } = data;

  const { seoTitle = undefined, metaDescription = undefined } = seo || {};

  const langLinks = generateLangLinks(translations);

  const pictures = {
    childImageSharp: {
      desktop,
      mobile,
    },
  };

  const parentPageContext =
    targetAudience === 'company'
      ? {
          path: formatMessage(messages.businessesPagePath),
          name: formatMessage(messages.businessesPageTitle),
        }
      : {
          path: formatMessage(messages.consumersPagePath),
          name: formatMessage(messages.consumersPageTitle),
        };

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        lang={pageContext.langKey}
        title={seoTitle}
        description={metaDescription}
        langLinks={langLinks}
      />

      <Hero pictures={pictures} primary={targetAudience !== 'company'}>
        <h1>
          <small>{parentPageContext.name}</small>
          <span css={hideVisually()}> - </span>
          {category.title}
        </h1>
      </Hero>

      <BreadcrumbContainer>
        <Breadcrumb>
          <BreadcrumbItem to={parentPageContext.path}>
            {parentPageContext.name}
          </BreadcrumbItem>

          <BreadcrumbItem to={path} isCurrentPage>
            {category.title}
          </BreadcrumbItem>
        </Breadcrumb>
      </BreadcrumbContainer>

      <ProductContainer>
        <CardGrid>
          {products.nodes.map((product) => {
            let picture;

            if (product.pictures.length > 0) {
              picture = product.pictures[0].asset.fluid;
            }

            return (
              <Card
                description={product.title}
                picture={picture}
                primary={targetAudience !== 'company'}
                to={generatePath(
                  product.slug.current,
                  pageContext.langKey,
                  'product'
                )}
              />
            );
          })}
        </CardGrid>
      </ProductContainer>

      <CTAContainer $primary={targetAudience !== 'company'}>
        <CTAWrapper>
          <Button
            to={parentPageContext.path}
            renderIcon={<IconArrow style={{ transform: 'rotate(180deg)' }} />}
            tag='link'
            outlined
            primary
            iconFirst
          >
            <FormattedMessage
              defaultMessage='Retour aux produits'
              description='CTA button to return to all the products'
            />
          </Button>
        </CTAWrapper>
      </CTAContainer>
    </Layout>
  );
};

ProductCategoryTemplate.propTypes = {
  data: PropTypes.shape().isRequired,
  path: PropTypes.string.isRequired,
  pageContext: PropTypes.shape({
    langKey: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProductCategoryTemplate;

export const query = graphql`
  query ProductCategoryTemplateQuery($id: String!) {
    category: sanityProductCategory(id: { eq: $id }) {
      targetAudience
      localization {
        ...alternativeLangHref
      }
      seo {
        metaDescription: meta_description
        seoTitle: seo_title
      }
      title
      targetAudience
      pictures {
        asset {
          heroPictureDesktop: fluid(maxWidth: 1088, maxHeight: 400) {
            ...GatsbySanityImageFluid
          }
          heroPictureMobile: fluid(maxWidth: 1024, maxHeight: 972) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }

    products: allSanityProduct(
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
      sort: { fields: sortOrder, order: ASC }
    ) {
      nodes {
        slug {
          current
        }
        title
        pictures {
          asset {
            fluid(maxWidth: 664, maxHeight: 408) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
