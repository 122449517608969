import React from 'react';
import PropTypes from 'prop-types';

import { Grid, GridItem } from './CardGrid.styles';
import Card from './Card';

const CardGrid = ({ children }) => {
  const nodes = React.Children.toArray(children);

  return (
    <Grid>
      {nodes.map(({ key, props }) => (
        <GridItem key={key}>
          <Card {...props} />
        </GridItem>
      ))}
    </Grid>
  );
};

CardGrid.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.shape({
        title: PropTypes.string.isRequired,
        titleAs: PropTypes.string,
        description: PropTypes.string,
        descriptionAs: PropTypes.string,
        picture: PropTypes.shape({}).isRequired,
        to: PropTypes.string.isRequired,
        primary: PropTypes.bool,
        tall: PropTypes.bool,
      }),
    })
  ).isRequired,
};

export default CardGrid;
