// vendors
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { em } from 'polished';

// styles
import { h2Style } from '@styles/global';
import { speed, easing } from '@styles/animation';

import { fontWeights } from '@src/styles/typography';
import { lessThan } from '../../../utils/mediaQuery';

export const PictureWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: ${({ theme }) => theme.colors.white};

  cursor: pointer;
`;

export const Label = styled.p`
  position: absolute;
  bottom: 0;
  left: 0;

  margin: 0.75em;
  padding: 1em;

  color: ${({ theme, $primary }) =>
    $primary ? theme.card.color : theme.card.primary.color};

  font-weight: ${fontWeights.light};
  text-transform: uppercase;

  background-color: ${({ theme }) => theme.card.primary.bg};

  ${({ $primary }) =>
    !$primary &&
    css`
      background-color: ${({ theme }) => theme.card.bg};
    `}
`;

export const Picture = styled(Img)`
  width: 100%;
  height: 100%;
`;

export const CardContent = styled.div`
  position: relative;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme }) => theme.spaces.sm1}rem;

  ${lessThan(480)} {
    padding: ${({ theme }) => theme.spaces.s0}rem;
  }

  color: inherit;

  ::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: ${({ theme }) => theme.card.bg};

    content: '';

    ${({ $primary }) =>
      !$primary &&
      css`
        background-color: ${({ theme }) => theme.card.primary.bg};
        mix-blend-mode: multiply;
      `}
  }
`;

export const CardText = styled.div`
  z-index: 1;

  align-self: center;
  margin-right: ${({ theme }) => theme.spaces.sm1}rem;
`;

export const Title = styled.p`
  margin: 0;

  ${h2Style}
`;

export const Description = styled.p`
  margin: 0;
  margin-top: ${({ theme }) => theme.spaces.sd4}rem;
`;

export const IconWrapper = styled.div`
  z-index: 1;

  display: inline-flex;
  flex: 0 0 ${53 / 22}em;

  align-items: center;
  justify-content: center;

  width: ${53 / 22}em;
  min-width: 0;

  height: ${53 / 22}em;

  padding: ${({ theme }) => theme.spaces.sd3}rem;

  border: ${({ theme }) => theme.card.icon.borderThin}px solid
    ${({ theme }) => theme.card.icon.borderColor};

  @media (prefers-reduced-motion: no-preference) {
    transition: color ${speed.superfast} ${easing.inQuint},
      background-color ${speed.superfast} ${easing.inQuint};
  }

  ${({ $primary }) =>
    $primary &&
    css`
      border-color: ${({ theme }) => theme.card.primary.icon.borderColor};
    `}
`;

export const Icon = styled.div`
  width: ${em(32, 19)};
  min-width: 9px;
`;

const StyledCard = styled(Link)`
  position: relative;

  display: flex;
  align-items: flex-start;

  min-height: 190px;

  overflow: hidden;

  color: ${({ theme, $primary }) =>
    $primary ? theme.card.primary.color : theme.card.color};
  text-decoration: none;

  ::before {
    display: block;
    width: 0.001px;

    padding-bottom: ${({ $tall }) =>
      $tall ? (700 / 652) * 100 : (400 / 652) * 100}%;

    content: '';
  }

  :hover,
  :focus,
  ${CardContent}:focus {
    ${IconWrapper} {
      color: ${({ theme }) => theme.card.hover.icon.color};

      background-color: ${({ theme }) => theme.card.hover.icon.bg};

      @media (prefers-reduced-motion: no-preference) {
        transition: color ${speed.fast} ${easing.outQuint},
          background-color ${speed.fast} ${easing.outQuint};
      }

      ${({ $primary }) =>
        $primary &&
        css`
          color: ${({ theme }) => theme.card.primary.hover.icon.color};

          background-color: ${({ theme }) => theme.card.primary.hover.icon.bg};
        `}
    }
  }
`;

export default StyledCard;
