import styled from 'styled-components';
import { rem } from 'polished';

export const BreadcrumbContainer = styled.div`
  max-width: 1456px;

  margin: ${rem(56, 22)} auto;
  padding: 0 24px;
`;

export const ProductContainer = styled.section`
  max-width: 1456px;

  margin: ${rem(56, 22)} auto ${rem(120, 22)};
  padding: 0 24px;
`;

export const CTAContainer = styled.div`
  margin: 120px 0 0;
  padding: ${rem(56, 22)} 24px;

  background-color: ${({ $primary, theme }) =>
    $primary ? theme.colors.turtleGreen : theme.colors.charcoal};
`;

export const CTAWrapper = styled.div`
  max-width: 1408px;

  margin: auto;
`;
