// vendors
import styled from 'styled-components';

export const List = styled.ol`
  padding: 0;

  color: ${({ theme }) => theme.breadcrumb.color};

  list-style: none;
`;

export const ListItem = styled.li`
  display: inline-block;

  :not(:last-child)::after {
    margin: 0 1ch;

    color: currentColor;

    content: '/';
  }
`;
