import React from 'react';

const IconPlus = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 34 34' {...props}>
    <g transform='translate(-2.316 .102)'>
      <path
        d='M0 0h34'
        transform='translate(2.316 16.898)'
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M0 0h34'
        transform='rotate(90 9.709 9.607)'
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
      />
    </g>
  </svg>
);

export default IconPlus;
