// vendors
import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledLink = styled(Link)`
  color: currentColor;

  text-decoration: none;
`;

const BreadcrumbItem = ({ to, children, onClick, isCurrentPage }) => {
  return (
    <StyledLink
      to={to}
      aria-current={isCurrentPage ? 'location' : undefined}
      onClick={onClick}
    >
      {children}
    </StyledLink>
  );
};

BreadcrumbItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isCurrentPage: PropTypes.bool,
};

BreadcrumbItem.defaultProps = {
  onClick: () => {},
  isCurrentPage: false,
};

export default BreadcrumbItem;
