// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// images
import IconPlus from '@images/IconPlus';

import StyledCard, {
  CardContent,
  CardText,
  IconWrapper,
  Icon,
  Title,
  Description,
  PictureWrapper,
  Picture,
  Label,
} from './Card.styles';

const Card = ({
  title,
  description,
  picture,
  to,
  primary,
  tall,
  titleAs,
  descriptionAs,
  label,
}) => {
  const source = {
    ...picture,
  };

  return (
    <StyledCard $tall={!!tall} $primary={!!primary} to={to}>
      <PictureWrapper>
        {picture.src && <Picture fluid={source} role='presentation' alt='' />}

        {label && <Label $primary={!!primary}>{label}</Label>}
      </PictureWrapper>

      <CardContent $primary={primary}>
        <CardText>
          <Title as={titleAs}>{title}</Title>

          {description && (
            <Description as={descriptionAs}>{description}</Description>
          )}
        </CardText>

        <IconWrapper $primary={!!primary}>
          <Icon>
            <IconPlus />
          </Icon>
        </IconWrapper>
      </CardContent>
    </StyledCard>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  titleAs: PropTypes.string,
  description: PropTypes.string,
  descriptionAs: PropTypes.string,
  to: PropTypes.string.isRequired,
  picture: PropTypes.shape({
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    aspecRatio: PropTypes.number,
    base64: PropTypes.string,
  }),
  primary: PropTypes.bool,
  tall: PropTypes.bool,
  label: PropTypes.string,
};

Card.defaultProps = {
  description: undefined,
  primary: false,
  tall: false,
  titleAs: undefined,
  descriptionAs: undefined,
  label: undefined,
  picture: {},
};

export default Card;

export const query = graphql`
  fragment CardLongPicture on File {
    childImageSharp {
      fluid(maxWidth: 664, maxHeight: 708, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }

  fragment CardShortPicture on File {
    childImageSharp {
      fluid(maxWidth: 664, maxHeight: 408, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`;
