import styled from 'styled-components';
import { rem } from 'polished';

export const Grid = styled.ul`
  margin: 0;
  padding: 0;

  list-style: none;

  ${({ theme }) => theme.layouts.grid('424px', rem(80, 22), 2)}
`;
export const GridItem = styled.li``;
